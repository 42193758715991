import {useState, UIEvent} from 'react';

import {GlobalState} from '../../reducers/index';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {i18n} from '../../locale/i18n';
import Header from '../parts/Headers/UpperLimitAmount';
import DocumentTitle from '../parts/DocumentTitle';
import SideBar, {selectedPages} from '../parts/SideBar';
import RestrictedPage from '../auth/RestrictedPage';
import './Index.scss';

import {
  BalanceHistoriesState,
  BalanceHistory,
} from '../../reducers/balanceHistories';
import {FranchiseStoresState} from '../../reducers/franchiseStores';
import fetchBalanceHistories from '../../actions/fetchBalanceHistories';
import Card from './Card';

type StatesFromReducer = BalanceHistoriesState & FranchiseStoresState;

interface DispatchProps {
  loadHistories: (cursor: string | null, employeeID: number) => void;
}

let viewBalanceHistories: BalanceHistory[] = [];
const Index = ({
  balanceHistories,
  reset,
  success,
  cursor,
  nextCursor,
  loadHistories,
  selected,
  loadingHistories,
}: DispatchProps & StatesFromReducer) => {
  const [openedIndexes, openIndex] = useState<{}>({});

  if (reset) {
    viewBalanceHistories = balanceHistories;
  } else if (success) {
    const historyIDHash = {};
    viewBalanceHistories = [
      ...viewBalanceHistories,
      ...balanceHistories,
    ].filter(({historyId}) =>
      historyIDHash[historyId] ? false : (historyIDHash[historyId] = true)
    );
  }

  const reload = (elementCount: number) => {
    if (!loadingHistories) {
      if (nextCursor) {
        if (selected) {
          loadHistories(nextCursor, selected.employeeID);
        }
      }
    }
  };

  const scrollAndReload = (e: UIEvent<HTMLElement>) => {
    if (!e.target) {
      return;
    }
    if (!(e.target instanceof HTMLElement)) {
      return;
    }
    const element: HTMLElement = e.target;
    if (!element.children[0]) {
      return;
    }
    if (!element.children[0].children[0]) {
      return;
    }
    const elementCount = balanceHistories.length;
    const cardHeight =
      element.children[0].children[0].getBoundingClientRect().height;
    const scrollTop = e.target.scrollTop;
    // 一番したから7つ目の要素が見えたらリロードする
    if (scrollTop > cardHeight * (elementCount - 7)) {
      reload(elementCount);
    }
    return;
  };
  return (
    <RestrictedPage>
      <DocumentTitle pageNameInTitle={i18n.t('history.history')} />
      <Header />
      <main id='accounting-history'>
        <link
          href='https://fonts.googleapis.com/css?family=Manrope'
          rel='stylesheet'
        />
        <section>
          <section onScroll={scrollAndReload}>
            {viewBalanceHistories.length === 0 && (
              <p className='history-empty'>{i18n.t('history.empty')}</p>
            )}
            <ul>
              {viewBalanceHistories.map((balanceHistory, i) => (
                <Card
                  key={i + balanceHistory.historyDate}
                  balanceHistory={balanceHistory}
                  nextOpened={openedIndexes[i + 1]}
                  opened={openedIndexes[i]}
                  toggle={() => {
                    openedIndexes[i] = !openedIndexes[i];
                    // openedIndexesのアドレスが変わらない(Objectの中身だけ変わる)とRenderされない
                    // Cardオブジェクト自体にOpen/notOpenを管理させたいがそれをすると、
                    // 手前の要素のスタイルを変えられないので親で管理している
                    openIndex({...openedIndexes});
                  }}
                />
              ))}
            </ul>
          </section>
          <SideBar selectedPage={selectedPages.balanceHistory} />
        </section>
        <footer>© Metaps Payment Inc.</footer>
      </main>
    </RestrictedPage>
  );
};

const mapStateToProps = (state: GlobalState): StatesFromReducer => ({
  ...state.franchiseStores,
  ...state.balanceHistories,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadHistories: (cursor: string | null, employeeID: number) =>
    dispatch(fetchBalanceHistories(cursor, employeeID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
