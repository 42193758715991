import './Detail.scss';
import ReactModal from 'react-modal';
import {NotificationItem} from '../../actions/fetchNotifications';
// import WhiteOutButton from '../../components/parts/WhiteOutButton';
import moment from 'moment-strftime';

interface Props {
  notification: NotificationItem | null;
  closeModal: () => void;
}

const NotificationDetail = ({notification, closeModal}: Props) => {
  if (!notification) {
    return <></>;
  }
  const notificationBody = notification.body;
  const urlPattern = /https?:\/\/\S+/g;

  const NotificationBodyView = (body: string) => {
    const urls = body.match(urlPattern);
    if (urls) {
      const messageArr = body.split(urlPattern);
      return messageArr.map((t, i) => {
        return (
          <>
            <p key={t}>{t}</p>
            <a key={urls[i]} href={urls[i]} target='_blank' rel='noreferrer'>
              {urls[i]}
            </a>
          </>
        );
      });
    } else {
      return <p>{body}</p>;
    }
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel='Settings'
      overlayClassName='overlay'
      className='notification-detail'
    >
      <section onClick={closeModal}>
        <h2>{notification.title}</h2>
        <p className='date'>
          {moment(notification.publishedAt).strftime('%Y年%m月%d日 %H時%m分')}
        </p>
        {NotificationBodyView(notificationBody)}
      </section>
    </ReactModal>
  );
};

export default NotificationDetail;
