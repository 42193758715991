import {i18n} from '../../locale/i18n';
import './SideBar.scss';
import {useState} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {UserInfoState} from '../../reducers/userInfo';
import {GlobalState} from '../../reducers/index';
import {FranchiseStoresState} from '../../reducers/franchiseStores';
import {NavLink} from 'react-router-dom';
import sendEventAnalytics from '../../actions/sendEventAnalytics';
import WhiteOutButton from './WhiteOutButton';
import {getOemName} from '../../util/oem';

type StatesFromReducer = UserInfoState & FranchiseStoresState;

interface DispatchProps {
  sendEvent: typeof sendEventAnalytics;
}

export enum selectedPages {
  prepayment,
  balanceHistory,
  notification,
  settings,
}

interface Props {
  selectedPage: selectedPages;
}

const SideBar = ({
  user,
  selected,
  selectedPage,
  sendEvent,
}: Props & StatesFromReducer & DispatchProps) => {
  const [menuOpend, openMenu] = useState(false);

  const oemName = getOemName();

  return (
    <>
      <section
        id='right-menu-links'
        style={
          menuOpend
            ? {
                right: 0,
              }
            : {
                right: '-260px',
              }
        }
        className={menuOpend ? 'opened' : ''}
      >
        <section className='buttons'>
          <ul>
            <NavLink
              to='/'
              onClick={() => sendEvent('Login', 'login link click')}
            >
              <li
                className={
                  selectedPage === selectedPages.prepayment ? 'selected' : ''
                }
              >
                <img
                  alt='prepayment'
                  src={`/img/side-bar/${oemName}/prepayment-apply${
                    selectedPage === selectedPages.prepayment ? '-selected' : ''
                  }.svg`}
                />
                <p>{i18n.t('application.requestAdvancePayment')}</p>
                <hr />
              </li>
            </NavLink>
            <NavLink
              to='/accounting-history'
              onClick={() => sendEvent('Login', 'login link click')}
            >
              <li
                className={
                  selectedPage === selectedPages.balanceHistory
                    ? 'selected'
                    : ''
                }
              >
                <img
                  alt='history'
                  src={`/img/side-bar/${oemName}/history${
                    selectedPage === selectedPages.balanceHistory
                      ? '-selected'
                      : ''
                  }.svg`}
                />
                <p>{i18n.t('history.history')}</p>
                <hr />
              </li>
            </NavLink>

            <NavLink
              to='/notification-list'
              onClick={() => sendEvent('Login', 'login link click')}
            >
              <li
                className={
                  selectedPage === selectedPages.notification ? 'selected' : ''
                }
              >
                <img
                  alt='notification'
                  src={`/img/side-bar/${oemName}/notification${
                    selectedPage === selectedPages.notification
                      ? '-selected'
                      : ''
                  }.svg`}
                />
                <p>{i18n.t('alert.notice')}</p>
                <hr />
              </li>
            </NavLink>
            <NavLink
              to='/settings'
              onClick={() => sendEvent('Login', 'login link click')}
            >
              <li
                className={
                  selectedPage === selectedPages.settings ? 'selected' : ''
                }
              >
                <img
                  alt='settings'
                  src={`/img/side-bar/${oemName}/settings${
                    selectedPage === selectedPages.settings ? '-selected' : ''
                  }.svg`}
                />
                <p>{i18n.t('settings.settings')}</p>
              </li>
            </NavLink>
          </ul>
        </section>
        <section className='helps'>
          <ul>
            <a
              rel='noopener noreferrer'
              href='/usage-seven-atm'
              target='_blank'
            >
              <li className='seven'>
                <img
                  alt='help-seven'
                  src={`/img/side-bar/${oemName}/help-seven.svg`}
                />
                <hr />
                <p>
                  セブン銀行ATMでの
                  <br />
                  受け取り方
                </p>
                <p />
                <img
                  alt='setting-tab'
                  src={`/img/side-bar/${oemName}/setting-tab.png`}
                />
              </li>
            </a>
            <a
              rel='noopener noreferrer'
              target='_blank'
              href={
                oemName === 'cria'
                  ? `https://www.metaps-payment.com/faq/faq_cria.html?language_code=${i18n.locale}`
                  : `https://www.metaps-payment.com/faq/faq_purikyu.html?language_code=${i18n.locale}`
              }
            >
              <li>
                <img
                  alt='help-faq'
                  src={`/img/side-bar/${oemName}/help-faq.svg`}
                />
                <p>{i18n.t('settings.faq')}</p>
                <img
                  alt='setting-tag'
                  src={`/img/side-bar/${oemName}/setting-tab.png`}
                />
              </li>
            </a>
          </ul>
        </section>
        <h3>
          {user ? user.surname : ''} {user ? user.givenName : ''}
        </h3>
        <p>{selected ? selected.franchiseStoreName : ''}</p>
        <WhiteOutButton
          onClick={() => {
            openMenu(false);
          }}
        >
          {'✕ ' + i18n.t('button.close')}
        </WhiteOutButton>
      </section>
      <button
        onClick={() => {
          openMenu(!menuOpend);
        }}
        className={menuOpend ? 'closed' : ''}
        id='right-menu-open-button'
      >
        <img alt='hamburger' src={`/img/side-bar/${oemName}/hamburger.png`} />
        <p>メニュー</p>
      </button>
    </>
  );
};

const mapStateToProps = (state: GlobalState): StatesFromReducer => ({
  ...state.userInfo,
  ...state.franchiseStores,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  sendEvent: (category, action) =>
    dispatch(sendEventAnalytics(category, action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
