import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {ButtonProps} from '@mui/material/Button';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {GlobalState} from '../../reducers/index';
import closeAlert from '../../actions/closeAlert';

interface IButtonProps extends ButtonProps {
  text: string;
}

export interface AlertConfig {
  title: string;
  message: string;
  buttons?: IButtonProps[];
}

interface StateProps {
  open?: boolean;
  config?: AlertConfig;
}

interface DispatchProps {
  closeAlert?: typeof closeAlert;
}

type Props = StateProps & DispatchProps;

export class Alert extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  public render() {
    const {open, config} = this.props;
    const {title, message, buttons} = config
      ? config
      : {title: null, message: null, buttons: null};

    const splittedMessages = (message || '').split('\n');

    const MessageRenderer = ({splittedMessage}: {splittedMessage: string}) => {
      const linkRegex = /<a\s*>(.*?)<\/a>/i;
      const match = linkRegex.exec(splittedMessage);

      if (match) {
        const linkText = match[1];
        return <a href={linkText}>{linkText}</a>;
      } else {
        return <p>{splittedMessage}</p>;
      }
    };
    return (
      <Dialog
        open={open || false}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {splittedMessages.map((t: string) => (
              <MessageRenderer splittedMessage={t} key={t} />
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttons &&
            buttons.map((button, i) => {
              const {text, onClick} = button;
              return (
                <Button onClick={this.handleClick(onClick)} key={i}>
                  {text}
                </Button>
              );
            })}
          {!buttons && <Button onClick={this.handleClick()}>OK</Button>}
        </DialogActions>
      </Dialog>
    );
  }

  private handleClick(onClick?: any) {
    return () => {
      if (onClick) {
        onClick();
      }
      if (this.props.closeAlert) {
        this.props.closeAlert();
      }
    };
  }
}

const mapStateToProps = (state: GlobalState) => state.alert;
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeAlert: () => dispatch(closeAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
