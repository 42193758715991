import constants from '../../constants';
import {Helmet} from 'react-helmet-async';

interface Props {
  pageNameInTitle: string;
}

// index.htmlのtitle, metaを上書き
const DocumentTitle = ({pageNameInTitle}: Props) => (
  <Helmet>
    <link rel='icon' href={`/${constants.oemCodeName}-favicon.ico`} />
    <title>
      {pageNameInTitle} | {`給与前払いサービス ${constants.appDisplayName}`}
    </title>
    <meta
      name='description'
      content={`いつでも申請が可能。申請した金額は最短で当日振込できる給与前払いサービス ${constants.appDisplayName}`}
    />
    <meta name='apple-itunes-app' content={`app-id=${constants.appId}`} />
    <meta property='og:url' content={`https://${constants.oemCodeName}.app/`} />
    <meta
      property='og:image'
      content={
        process.env.REACT_APP_OEM_CODE === '1'
          ? 'https://cria.app/cria/icon.jpg'
          : 'https:/prekyu.cria.app/prekyu/icon.jpg'
      }
    />
    <meta
      property='og:title'
      content={`給与前払いサービス${constants.appDisplayName}`}
    />
    <meta
      property='og:site_name'
      content={`給与前払いサービス${constants.appDisplayName}`}
    />
    <meta
      property='og:description'
      content={`いつでも申請が可能。申請した金額は最短で当日振込できる給与前払いサービス${constants.appDisplayName}`}
    />
  </Helmet>
);

export default DocumentTitle;
