import {call, put, takeEvery} from 'redux-saga/effects';
import {AUTHENTICATION_FAILURE_COMPLETE} from '../actions/authFailure';
import fetchAuthToken, {
  FetchAuthTokenSuccess,
  FETCH_AUTH_TOKEN,
  FETCH_AUTH_TOKEN_ERROR,
  FETCH_AUTH_TOKEN_FAILURE,
  FETCH_AUTH_TOKEN_SUCCESS,
  FETCH_CONFIRMATION_CODE_TOKEN_SUCCESS,
  USE_ANOTHER_OEM_APP,
} from '../actions/fetchAuthToken';
import requestPasswordReset, {
  RequestPasswordResetSuccess,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_ERROR,
  REQUEST_PASSWORD_RESET_SUCCESS,
} from '../actions/requestPasswordReset';

import requestCustomerNoReminder, {
  REQUEST_CUSTOMER_NO_REMINDER,
  REQUEST_CUSTOMER_NO_REMINDER_ERROR,
  REQUEST_CUSTOMER_NO_REMINDER_SUCCESS,
} from '../actions/requestCustomerNoReminder';
import verifyAuthToken, {
  VERIFY_AUTH_TOKEN,
  VERIFY_AUTH_TOKEN_ERROR,
  VERIFY_AUTH_TOKEN_SUCCESS,
} from '../actions/verifyAuthToken';
import {SHOW_ALERT} from '../actions/showAlert';
import postRequestPasswordResetToken from '../api/postRequestPasswordResetToken';
import postRequestCustomerNoReminder from '../api/postRequestCustomerNoReminderToken';
import removeAuthToken, {
  REMOVE_AUTH_TOKEN,
  REMOVE_AUTH_TOKEN_COMPLETED,
} from '../actions/removeAuthToken';

import removeAppPass, {REMOVE_APP_PASS} from '../actions/removeAppPass';

import moment from 'moment';
import constants from '../constants';
import {i18n} from '../locale/i18n';

import postAuthToken from '../api/postAuthToken';
import putAuthToken from '../api/putAuthToken';
import deleteAuthToken from '../api/deleteAuthToken';
import {encode} from '../util/password';
import localStorage from '../util/localStorage';
import {Context} from '../sagas/index';
import reportError from './reportError';
import {FETCH_FRANCHISE_STORES} from '../actions/fetchFranchiseStores';
import {SEND_EVENT_ANALYTICS} from '../actions/sendEventAnalytics';
import {AGREE_WITH_THE_TERMS} from '../actions/agreeWithTheTerms';
const PASSWORD_ENCODE_PREFIX = 'maebarai-p:';

function* handleVerifyAuthToken(action: ReturnType<typeof verifyAuthToken>) {
  const refreshToken: string = yield localStorage.refreshToken;
  const authToken: string = yield localStorage.authToken;

  if (emptyString(refreshToken) || emptyString(authToken)) {
    yield put({type: AUTHENTICATION_FAILURE_COMPLETE});
    return;
  }

  const instanceID: string = yield localStorage.instanceID;

  try {
    const resp: {data: {refreshToken: string; authToken: string}} = yield call(
      putAuthToken,
      instanceID,
      refreshToken,
      authToken
    );
    yield (localStorage.refreshToken = resp.data.refreshToken);

    const newAuthToken = resp.data.authToken;
    yield (localStorage.authToken = newAuthToken);
    const isAppPasswordSet = !!localStorage.appPassword;

    yield put({
      type: FETCH_FRANCHISE_STORES,
    });

    // pass auth token for notification detail
    yield put({
      type: VERIFY_AUTH_TOKEN_SUCCESS,
      payload: {authToken: newAuthToken, isAppPasswordSet},
    });
  } catch (e: any) {
    yield localStorage.removeAuthToken();
    yield localStorage.removeRefreshToken();

    if (e.response && e.response.status === 403) {
      yield put({
        type: AUTHENTICATION_FAILURE_COMPLETE,
      });
      return;
    }

    yield reportError(e, VERIFY_AUTH_TOKEN_ERROR);
  }
}

const emptyString = (str: string) => str === null || str.length === 0;

function* handleFetchAuthToken(action: ReturnType<typeof fetchAuthToken>) {
  const encodedPassword: string = encodePassword(action.payload.password);
  try {
    const instanceID = localStorage.instanceID;
    const resp: {
      data: {
        refreshToken: string;
        authToken: string;
        confirmationCodeToken: string;
        mailAddress: string;
        phoneNumber: string;
      };
    } = yield call(
      postAuthToken,
      action.payload.customerNo,
      encodedPassword,
      instanceID
    );

    if (resp.data.confirmationCodeToken) {
      const {confirmationCodeToken, mailAddress, phoneNumber} = resp.data;

      const {DATE_RFC2822, confirmationCodeRecreateIntervalSeconds} = constants;
      const recreatablesAt = moment()
        .add(confirmationCodeRecreateIntervalSeconds, 's')
        .format(DATE_RFC2822);

      yield (localStorage.confirmationCodeToken = confirmationCodeToken);
      yield (localStorage.recreatablesAt = recreatablesAt);
      yield (localStorage.confirmationCodeSendMailAddress = mailAddress);
      yield (localStorage.confirmationCodeSendPhoneNumber = phoneNumber);

      yield put({
        type: FETCH_CONFIRMATION_CODE_TOKEN_SUCCESS,
        payload: {
          recreatablesAt,
          mailAddress,
          phoneNumber,
        },
        meta: action.meta,
      });
    } else if (resp.data.refreshToken && resp.data.authToken) {
      yield (localStorage.refreshToken = resp.data.refreshToken);

      const newAuthToken = resp.data.authToken;
      yield (localStorage.authToken = newAuthToken);
      const isAppPasswordSet = !!localStorage.appPassword;

      yield put({
        type: FETCH_FRANCHISE_STORES,
      });

      yield put({
        type: FETCH_AUTH_TOKEN_SUCCESS,
        payload: {authToken: newAuthToken, isAppPasswordSet},
        meta: action.meta,
      });
    }
  } catch (e: any) {
    if (e.response?.status === 409) {
      yield put({
        type: USE_ANOTHER_OEM_APP,
      });
      yield put({
        type: SHOW_ALERT,
        payload: {
          config: {
            title: null,
            message: i18n.t('alert.useAnotherOemApp'),
          },
        },
      });
      return;
    }
    if (e.response?.status === 451) {
      yield (localStorage.refreshToken = e.response.data.authToken);
      yield (localStorage.authToken = e.response.data.refreshToken);
      yield put({
        type: AGREE_WITH_THE_TERMS,
      });
      yield put({
        type: SHOW_ALERT,
        payload: {
          config: {
            title: null,
            message: i18n.t('settings.needAgreeWithTheTerms'),
          },
        },
      });
      return;
    }
    if (e.response && [401, 403].includes(e.response.status)) {
      yield reportError(e, FETCH_AUTH_TOKEN_FAILURE);

      return;
    }

    yield reportError(e, FETCH_AUTH_TOKEN_ERROR);
  }
}

function* handleFetchAuthTokenSuccess(
  context: Context,
  action: FetchAuthTokenSuccess
) {
  const {meta} = action;
  if (meta.routeOnSuccess) {
    yield call(context.history.replace, meta.routeOnSuccess);
  }
}

function* handleRemoveAuthToken(action: ReturnType<typeof removeAuthToken>) {
  try {
    const instanceID: string = yield localStorage.instanceID;
    const refreshToken: string = yield localStorage.refreshToken;
    const authToken: string = yield localStorage.authToken;

    yield localStorage.removeAuthToken();
    yield localStorage.removeRefreshToken();

    yield deleteAuthToken(refreshToken, instanceID, authToken);

    yield put({
      type: SEND_EVENT_ANALYTICS,
      payload: {
        category: 'Login',
        action: 'logout',
      },
    });
  } catch (e: any) {
    // do nothing
  }

  yield put({type: REMOVE_AUTH_TOKEN_COMPLETED});
}

function* handleRemoveAppPass(action: ReturnType<typeof removeAppPass>) {
  try {
    yield localStorage.removeAppPass();
  } catch (e: any) {
    // do nothing
  }
}

function* handleRequestPasswordReset(
  action: ReturnType<typeof requestPasswordReset>
) {
  try {
    const instanceID: string = yield localStorage.instanceID;

    const resp: {data: string} = yield call(
      postRequestPasswordResetToken,
      action.payload.customerNo,
      instanceID
    );

    yield put({
      type: REQUEST_PASSWORD_RESET_SUCCESS,
      payload: {customerNo: resp.data},
      meta: action.meta,
    });
  } catch (e: any) {
    if (e.response) {
      if (e.response.status === 400) {
        yield put({
          type: REQUEST_PASSWORD_RESET_ERROR,
          payload: {message: e.response.data.message},
        });
        // yield reportError(e, REQUEST_PASSWORD_RESET_ERROR);
        return;
      }
    }

    yield put({
      type: REQUEST_PASSWORD_RESET_ERROR,
      error: true,
      payload: {error: e},
    });
  }
}

function* handleRequestPasswordResetSuccess(
  context: Context,
  action: RequestPasswordResetSuccess
) {
  if (action.meta) {
    yield put({
      type: SHOW_ALERT,
      payload: {
        config: action.meta.alertConfig,
      },
    });
  }
}

function* handleRequestCustomerNoReminder(
  action: ReturnType<typeof requestCustomerNoReminder>
) {
  try {
    const instanceID: string = yield localStorage.instanceID;
    const resp: {data: string} = yield call(
      postRequestCustomerNoReminder,
      action.payload.mailAddress,
      action.payload.phoneNumber,
      instanceID
    );

    const {meta} = action;
    yield put({
      type: REQUEST_CUSTOMER_NO_REMINDER_SUCCESS,
      payload: {mailAddress: resp.data, phoneNumber: resp.data},
      meta,
    });
  } catch (e: any) {
    if (e.response) {
      if (e.response.status === 400) {
        yield put({
          type: REQUEST_CUSTOMER_NO_REMINDER_ERROR,
          payload: {message: e.response.data.message},
        });
        // yield reportError(e, REQUEST_CUSTOMER_NO_REMINDER_ERROR);
        return;
      }
    }

    yield put({
      type: REQUEST_CUSTOMER_NO_REMINDER_ERROR,
      error: true,
      payload: {error: e},
    });
  }
}

function* handlePassRequestCustomerNoReminder(
  context: Context,
  action: ReturnType<typeof requestCustomerNoReminder>
) {
  if (action.meta) {
    yield put({
      type: SHOW_ALERT,
      payload: {
        config: action.meta.alertConfig,
      },
    });
  }
}

const encodePassword = (password: string) => {
  return encode(
    password,
    PASSWORD_ENCODE_PREFIX,
    process.env.REACT_APP_SECRET_KEY || ''
  );
};

function* authTokenSaga(context: Context) {
  yield takeEvery(VERIFY_AUTH_TOKEN, handleVerifyAuthToken);
  yield takeEvery(FETCH_AUTH_TOKEN, handleFetchAuthToken);
  yield takeEvery(
    FETCH_AUTH_TOKEN_SUCCESS,
    handleFetchAuthTokenSuccess,
    context
  );

  yield takeEvery(
    FETCH_AUTH_TOKEN_SUCCESS,
    handleFetchAuthTokenSuccess,
    context
  );

  yield takeEvery(REQUEST_PASSWORD_RESET, handleRequestPasswordReset);
  yield takeEvery(
    REQUEST_PASSWORD_RESET_SUCCESS,
    handleRequestPasswordResetSuccess,
    context
  );
  yield takeEvery(
    REQUEST_CUSTOMER_NO_REMINDER,
    handleRequestCustomerNoReminder
  );
  yield takeEvery(
    REQUEST_CUSTOMER_NO_REMINDER_SUCCESS,
    handlePassRequestCustomerNoReminder,
    context
  );
  yield takeEvery(REMOVE_AUTH_TOKEN, handleRemoveAuthToken);
  yield takeEvery(REMOVE_APP_PASS, handleRemoveAppPass);
}

export default authTokenSaga;
