import {
  AuthenticationFailureAction,
  AUTHENTICATION_FAILURE_COMPLETE,
} from '../actions/authFailure';
import {
  FetchAuthTokenAction,
  FETCH_AUTH_TOKEN,
  FETCH_AUTH_TOKEN_ERROR,
  FETCH_AUTH_TOKEN_FAILURE,
  FETCH_AUTH_TOKEN_SUCCESS,
  FETCH_CONFIRMATION_CODE_TOKEN_SUCCESS,
  USE_ANOTHER_OEM_APP,
} from '../actions/fetchAuthToken';
import {
  VerifyAuthTokenAction,
  VERIFY_AUTH_TOKEN,
  VERIFY_AUTH_TOKEN_ERROR,
  VERIFY_AUTH_TOKEN_SUCCESS,
} from '../actions/verifyAuthToken';
import {
  RemoveAuthTokenAction,
  REMOVE_AUTH_TOKEN,
  REMOVE_AUTH_TOKEN_COMPLETED,
} from '../actions/removeAuthToken';
import {
  RedirectToLoginAction,
  REDIRECT_TO_LOGIN,
} from '../actions/redirectToLogin';
import {
  CLOSE_APP_PASS_DIALOG,
  CloseAppPassDialogAction,
} from '../actions/closeAppPassDialog';
import {
  AgreeWithTheTermsType,
  AGREE_WITH_THE_TERMS,
  POST_AGREE_WITH_THE_TERMS_FAILURE,
  POST_AGREE_WITH_THE_TERMS_SUCCESS,
  CANCEL_AGREE_WITH_THE_TERMS,
} from '../actions/agreeWithTheTerms';

type AuthTokenAction =
  | AuthenticationFailureAction
  | VerifyAuthTokenAction
  | FetchAuthTokenAction
  | RemoveAuthTokenAction
  | RedirectToLoginAction
  | CloseAppPassDialogAction
  | AgreeWithTheTermsType;

export interface AuthTokenState {
  showConfirmationCode: boolean;
  showPasscodeDialog: boolean;
  passwordResetCompleted: boolean;
  loggedIn?: boolean;
  returnPathname: string;
  recreatablesAt?: string;
  confirmationCodeSendMailAddress?: string;
  confirmationCodeSendPhoneNumber?: string;
  showTermOfUse: boolean;
}

const initialState: AuthTokenState = {
  showConfirmationCode: false,
  showPasscodeDialog: false,
  passwordResetCompleted: false,
  loggedIn: undefined,
  recreatablesAt: '',
  returnPathname: '',
  showTermOfUse: false,
};

const authTokenReducer = (state = initialState, action: AuthTokenAction) => {
  switch (action.type) {
    case VERIFY_AUTH_TOKEN:
      return Object.assign({}, state, {});
    case VERIFY_AUTH_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        showPasscodeDialog: !action.payload.isAppPasswordSet, // アプリパスワードが設定されていない場合は設定させる
        loggedIn: true,
        authToken: action.payload.authToken,
      });
    case VERIFY_AUTH_TOKEN_ERROR:
      return Object.assign({}, state, {});
    case FETCH_AUTH_TOKEN:
      return Object.assign({}, state, {});
    case FETCH_AUTH_TOKEN_SUCCESS:
    case POST_AGREE_WITH_THE_TERMS_SUCCESS:
      return Object.assign({}, state, {
        showPasscodeDialog: !action.payload.isAppPasswordSet, // アプリパスワードが設定されていない場合は設定させる
        loggedIn: true,
        authToken: action.payload.authToken,
        message: null,
        showTermOfUse: false,
      });
    case FETCH_AUTH_TOKEN_FAILURE:
      return Object.assign({}, state, {});
    case FETCH_AUTH_TOKEN_ERROR:
      return Object.assign({}, state, {});
    case FETCH_CONFIRMATION_CODE_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        showConfirmationCode: true,
        loggedIn: false,
        recreatablesAt: action.payload.recreatablesAt,
        confirmationCodeSendMailAddress: action.payload.mailAddress,
        confirmationCodeSendPhoneNumber: action.payload.phoneNumber,
        message: null,
      });
    case USE_ANOTHER_OEM_APP:
      return Object.assign({}, state, {
        loading: false,
        loggedIn: false,
      });
    case REMOVE_AUTH_TOKEN:
      return Object.assign({}, state, {
        showConfirmationCode: false,
        showPasscodeDialog: false,
      });
    case REMOVE_AUTH_TOKEN_COMPLETED:
      return Object.assign({}, state, {
        showConfirmationCode: false,
        loggedIn: false,
        authToken: null,
      });
    case AUTHENTICATION_FAILURE_COMPLETE:
      return Object.assign({}, state, {
        loggedIn: false,
      });
    case REDIRECT_TO_LOGIN:
      return Object.assign({}, state, {
        returnPathname: action.payload.pathname,
      });
    case CLOSE_APP_PASS_DIALOG:
      return Object.assign({}, state, {
        showPasscodeDialog: false,
      });
    case AGREE_WITH_THE_TERMS:
      return Object.assign({}, state, {
        showTermOfUse: true,
      });
    case POST_AGREE_WITH_THE_TERMS_FAILURE:
      return Object.assign({}, state, {
        showTermOfUse: false,
      });
    case CANCEL_AGREE_WITH_THE_TERMS:
      return Object.assign({}, state, {
        loggedIn: false,
        showTermOfUse: false,
      });

    default:
      return state;
  }
};

export default authTokenReducer;
